import { Link } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '../../src/components/Text';

type Props = {
  text: string;
  link: string;
  activeLink: string;
  icon: React.ReactNode;
  onPress: () => void;
};

export const DrawerItem: React.FC<Props> = ({ text, link, activeLink, icon, onPress }) => {
  return (
    <Link to={link} onPress={onPress}>
      <View style={styles.container}>
        {icon}
        <Text variant='h3' style={{ fontWeight: activeLink === link ? '500' : '400' }}>
          {text}
        </Text>
      </View>
    </Link>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    marginBottom: 16,
    width: '100%',
    paddingLeft: 8,
  },
});
