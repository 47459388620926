import { useCallback, useEffect, useState } from 'react';
import type { Event } from 'stream-chat';
import { getChatClient } from '../../src/utils/common';

const chatClient = getChatClient();

const useNewMessageNotification = () => {
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);

  const handleChatEvent = useCallback((event: Event) => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement | null;

    if (event.type === 'message.new' && event?.unread_count && event.unread_count > 0) {
      const userName = event?.user && event.user.name;
      const message = event?.message && event.message.text;
      new Notification(userName ?? '', {
        body: message ?? '',
      });
    }

    if (event.type === 'message.read' && !event.total_unread_count && favicon != null) {
      favicon.href = '../../src/assets/images/logo.png';
    }
  }, []);

  useEffect(() => {
    const clientEventListener = chatClient?.on(handleChatEvent);

    if (
      window.Notification &&
      Notification.permission !== 'granted' &&
      Notification.permission !== 'denied'
    ) {
      setShowNotificationBanner(true);
    }

    return () => {
      clientEventListener?.unsubscribe();
    };
  }, [handleChatEvent]);

  const grantPermission = () => {
    if (Notification.permission === 'granted') {
      new Notification('You are already subscribed to web notifications');
      return;
    }

    if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((result) => {
        if (result === 'granted') {
          new Notification('New message from Agave', {
            body: 'Nice, notifications are now enabled!',
          });
        }
      });
    }

    setShowNotificationBanner(false);
  };

  return {
    showNotificationBanner,
    grantPermission,
  };
};

export default useNewMessageNotification;
