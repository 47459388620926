import { create } from 'zustand';
import { fetchProgramById } from '../api/fetchProgramById';
import { trackActivity } from '../api/trackActivity';
import { updateProgram } from '../api/updateProgram';
import type { Lesson, Program, ProgramAnswers } from '../global/types';
import { ActivityType, LessonStatus } from '../global/types';
import { useTherapyStore as therapyStore } from './useTherapyStore';

type ProgramStore = {
  isLoading: boolean;
  currentProgramId: string | null;
  currentProgram: Program | null;
  currentLesson: Lesson | null;
  programAnswers: ProgramAnswers;
  setIsLoading: (isLoading: boolean) => void;
  setCurrentProgramId: (currentProgramId: string) => void;
  fetchProgram: () => void;
  setLesson: (lesson: Lesson, lessonNumber: number) => void;
  completeStep: (slug: string, isLastStep?: boolean) => void;
  checkCorrectAnswers: () => number;
  appendAnswers: (slug: string, answers: string[]) => void;
  getAnswersBySlug: (slug: string) => string[];
  trackSkipVideoPress: () => void;
  trackVideoScriptPress: () => void;
};

export const useProgramStore = create<ProgramStore>()((set, get) => ({
  isLoading: false,
  currentProgramId: null,
  currentProgram: null,
  currentLesson: null,
  programAnswers: { lessons: {} },
  setIsLoading: (isLoading) => set({ isLoading }),
  setCurrentProgramId: (currentProgramId) => set({ currentProgramId }),
  fetchProgram: async () => {
    const { currentProgramId, setIsLoading } = get();
    setIsLoading(true);
    if (!currentProgramId) return;
    const data = await fetchProgramById(currentProgramId);
    if (data) {
      set({
        currentProgram: data.program,
        programAnswers: data.answers
          ? data.answers
          : {
              lessons: {
                [`${currentProgramId}-lesson-1`]: {
                  status: LessonStatus.active,
                  steps: {},
                },
              },
            },
      });
    }
    setIsLoading(false);
  },
  setLesson: (lesson, lessonNumber) => {
    const { currentProgramId } = get();
    set({ currentLesson: lesson });
    trackActivity(ActivityType.LessonStarted, {
      cbtProgramUid: currentProgramId as string,
      lessonId: lesson.slug,
      lessonNumber,
    });
  },
  completeStep: async (slug, isLastStep) => {
    let lessonNumber = 0;
    const { currentProgramId, currentProgram, currentLesson, programAnswers } = get();

    if (isLastStep) {
      const therapy = therapyStore.getState().therapy;
      currentProgram?.lessons.forEach((lesson, index) => {
        if (lesson.slug === currentLesson?.slug) {
          lessonNumber = index + 1;
          programAnswers.lessons[lesson?.slug].status = LessonStatus.done;
          const nextLesson = currentProgram?.lessons[index + 1];
          const nextLessonAnswers = programAnswers.lessons[nextLesson?.slug]?.steps;
          if (nextLesson && !nextLessonAnswers) {
            programAnswers.lessons[nextLesson?.slug] = { status: LessonStatus.active, steps: {} };
          }
          set({ programAnswers: JSON.parse(JSON.stringify(programAnswers)) });
        }
      });

      await trackActivity(ActivityType.LessonDone, {
        cbtProgramUid: currentProgramId as string,
        lessonId: currentLesson?.slug as string,
        lessonNumber,
        ...(therapy && {
          therapyId: therapy.id,
          flowType: 'therapy',
        }),
      });
    }

    await updateProgram(currentProgramId as string, slug, programAnswers);
  },
  appendAnswers: (slug, answers) => {
    const { currentProgram, currentLesson, programAnswers } = get();

    currentProgram?.lessons.forEach((lesson) => {
      if (lesson.slug === currentLesson?.slug) {
        programAnswers.lessons[lesson.slug].steps = {
          ...programAnswers.lessons[lesson.slug].steps,
          [slug]: { answers },
        };
        set({ programAnswers: JSON.parse(JSON.stringify(programAnswers)) });
      }
    });
  },
  checkCorrectAnswers: () => {
    let correctAnswers = 0;
    const { currentProgram, currentLesson, programAnswers } = get();

    currentProgram?.lessons.forEach((lesson) => {
      if (lesson.slug === currentLesson?.slug) {
        const steps = Object.values(lesson.steps);
        steps.forEach((step) => {
          if (step.question) {
            const answers =
              programAnswers?.lessons?.[lesson?.slug]?.steps?.[step?.slug]?.answers || [];
            const isCorrect = answers?.every((v, i) => step?.question?.answer?.[i] === v);
            if (isCorrect) correctAnswers++;
          }
        });
      }
    });

    return correctAnswers;
  },
  getAnswersBySlug: (slug) => {
    const { currentProgram, programAnswers } = get();
    let answers: string[] = [];

    currentProgram?.lessons.forEach((lesson) => {
      if (lesson.steps[slug]) {
        answers = programAnswers.lessons[lesson.slug]?.steps[slug]?.answers || [];
      }
    });

    return answers;
  },
  trackSkipVideoPress: () => {
    const { currentProgramId, currentLesson } = get();
    trackActivity(ActivityType.VideoSkipped, {
      cbtProgramUid: currentProgramId as string,
      lessonId: currentLesson?.slug as string,
    });
  },
  trackVideoScriptPress: () => {
    const { currentProgramId, currentLesson } = get();
    trackActivity(ActivityType.VideoScriptOpened, {
      cbtProgramUid: currentProgramId as string,
      lessonId: currentLesson?.slug as string,
    });
  },
}));
