import { useAuth0 } from '@auth0/auth0-react';
import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from '../../src/components/Button';
import { Text } from '../../src/components/Text';
import { colors } from '../../src/theme/constants';

export const SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  const handleAuth = async (hint: string) => {
    await loginWithRedirect({
      authorizationParams: { screen_hint: hint },
    });
  };

  return (
    <View style={styles.container}>
      <Text variant='h3' style={{ textAlign: 'center' }}>
        Log in or create an account to get access to all features of Agave Health
      </Text>
      <Player
        style={{ width: '80%', height: '80%' }}
        src={require('../../src/assets/lottie/signin.json')}
        autoplay
        loop
      />
      <View style={{ gap: 16, marginBottom: 32 }}>
        <Button title='Sign up' onPress={() => handleAuth('signup')} />
        <Button title='Log in' variant='outlined' onPress={() => handleAuth('signin')} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    backgroundColor: colors.beige100,
  },
});
