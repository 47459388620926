import type { ActivityType } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';

export const trackActivity = async (
  activityType: ActivityType,
  data: { [key: string]: number | string | string[] },
) => {
  const url = 'activity';

  try {
    await api.post(url, { json: { activityType, data } });
  } catch (error) {
    logError(`POST -> ${url}`, error);
  }
};
