import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { colors } from '../theme/constants';
import { Text } from './Text';

type Props = {
  isSelected: boolean;
  text: string;
  onSelect: () => void;
};

export const OptionItem: React.FC<Props> = ({ isSelected, text, onSelect }) => {
  return (
    <TouchableOpacity
      onPress={onSelect}
      style={[
        styles.container,
        {
          backgroundColor: isSelected ? colors.green600 : colors.green100,
        },
      ]}
    >
      <Text
        variant='body1'
        style={{
          textAlign: 'center',
          color: isSelected ? colors.white100 : colors.black600,
          fontWeight: isSelected ? '700' : '400',
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 72,
    borderRadius: 8,
    paddingHorizontal: 16,
  },
});
