// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/stream-chat-react/dist/css/v2/index.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/react-modern-drawer/dist/index.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/react-toastify/dist/ReactToastify.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #fcfaf8;
}

#app-root {
  background-color: #fcfaf8;
  display: flex;
  flex: 1 1 100%;
  height: 100vh;
  font-family: 'Catamaran';
}

.react-markdown,
.Toastify__toast-body {
  font-family: 'Catamaran';
}

.str-chat {
  --str-chat__primary-color: #e07a3d;
  --str-chat__active-primary-color: #004d40;
  --str-chat__surface-color: #e07a3d;
  --str-chat__secondary-surface-color: #e1e1e1;
  --str-chat__primary-surface-color: #cfe5e0;
  --str-chat__font-family: 'Catamaran';
}

.str-chat__message-text-inner {
  line-height: 20px;
}

.str-chat__virtual-list {
  padding-top: 10px;
}

#downloadApp {
  background-image: linear-gradient(rgb(224, 122, 61), rgb(35, 83, 71));
}
`, "",{"version":3,"sources":["webpack://./web/global/styles.css"],"names":[],"mappings":"AAIA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,cAAc;EACd,aAAa;EACb,wBAAwB;AAC1B;;AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,kCAAkC;EAClC,yCAAyC;EACzC,kCAAkC;EAClC,4CAA4C;EAC5C,0CAA0C;EAC1C,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qEAAqE;AACvE","sourcesContent":["@import '~stream-chat-react/dist/css/v2/index.css';\n@import 'react-modern-drawer/dist/index.css';\n@import 'react-toastify/dist/ReactToastify.css';\n\nbody {\n  background-color: #fcfaf8;\n}\n\n#app-root {\n  background-color: #fcfaf8;\n  display: flex;\n  flex: 1 1 100%;\n  height: 100vh;\n  font-family: 'Catamaran';\n}\n\n.react-markdown,\n.Toastify__toast-body {\n  font-family: 'Catamaran';\n}\n\n.str-chat {\n  --str-chat__primary-color: #e07a3d;\n  --str-chat__active-primary-color: #004d40;\n  --str-chat__surface-color: #e07a3d;\n  --str-chat__secondary-surface-color: #e1e1e1;\n  --str-chat__primary-surface-color: #cfe5e0;\n  --str-chat__font-family: 'Catamaran';\n}\n\n.str-chat__message-text-inner {\n  line-height: 20px;\n}\n\n.str-chat__virtual-list {\n  padding-top: 10px;\n}\n\n#downloadApp {\n  background-image: linear-gradient(rgb(224, 122, 61), rgb(35, 83, 71));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
