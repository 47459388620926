import { useAuth0 } from '@auth0/auth0-react';
import { NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { X_IMPERSONATE } from '../../src/global/constants';
import { useUserStore } from '../../src/store/useUserStore';
import { colors } from '../../src/theme/constants';
import { storage } from '../../src/utils/localStorage';
import { Loading } from '../components/Loading';
import { AuthenticatedStack } from './AuthenticatedStack';
import { UnAuthenticatedStack } from './UnAuthenticatedStack';

export const AppNavigation = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [hasImpersonateId, setHasImpersonateId] = useState(false);
  const authToken = useUserStore((state) => state.authToken);
  const setAuthToken = useUserStore((state) => state.setAuthToken);

  const linking = {
    prefixes: __DEV__ ? ['https://localhost:5173'] : ['https://app.agavehealth.com'],
    config: {
      screens: {
        SignIn: 'signin',
        Home: 'home',
        Chat: 'chat',
        Settings: 'settings',
        DrawerStack: '',
        OnboardingUserDetails: 'onboarding-user-details',
        OnboardingQuestion: 'onboarding-question',
        SwitchCoach: 'choose-coach',
        Payment: 'payment',
      },
    },
  };

  useEffect(() => {
    const impersonateId = storage.getString(X_IMPERSONATE);
    setHasImpersonateId(Boolean(impersonateId));
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      const token = await getAccessTokenSilently();
      token && setAuthToken(token);
    })();
  }, [getAccessTokenSilently, isAuthenticated, setAuthToken]);

  if (isLoading) return <Loading />;

  return (
    <NavigationContainer linking={linking} documentTitle={{ formatter: () => 'Agave Health' }}>
      <View
        style={{
          flex: 1,
          borderWidth: hasImpersonateId ? 5 : 0,
          borderColor: hasImpersonateId ? colors.red500 : 'transparent',
        }}
      >
        {isAuthenticated && authToken ? <AuthenticatedStack /> : <UnAuthenticatedStack />}
      </View>
    </NavigationContainer>
  );
};
