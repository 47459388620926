import { useCallback, useEffect, useState } from 'react';
import Purchases, { type PurchasesOffering } from 'react-native-purchases';
import { IOS_REVCAT_API_KEY } from '../global/envs';
import type { User } from '../global/types';
import { SubscriptionStatus } from '../global/types';
import { useUserStore as userStore } from '../store/useUserStore';
import { logError } from '../utils/common';

export const setupPurchases = (userId: string | undefined) => {
  if (!userId) return;

  const apiKey = IOS_REVCAT_API_KEY;
  Purchases.configure({ apiKey, appUserID: userId });
};

export const hasPaidWithApplePay = async () => {
  try {
    const customerInfo = await Purchases.getCustomerInfo();
    if (typeof customerInfo.entitlements.active.premium !== 'undefined') {
      return customerInfo.entitlements.active.premium.store === 'APP_STORE';
    }
  } catch (error) {
    logError('usePurchases -> hasPaidWithApplePay', error);
  }
};

export const checkIsUserPaid = (user: User) => {
  const { subscriptionStatus, accessDaysLeft = 0 } = user;

  const isPaid =
    ([SubscriptionStatus.CANCELED, SubscriptionStatus.TRIAL].includes(subscriptionStatus) &&
      accessDaysLeft > 0) ||
    subscriptionStatus === SubscriptionStatus.PREMIUM ||
    user.isCoach ||
    user.isAlpha;

  if (isPaid) userStore.getState().setIsPaidUser();
};

export const restorePurchase = async () => {
  try {
    const restoreInfo = await Purchases.restorePurchases();
    if (typeof restoreInfo.entitlements.active.premium !== 'undefined') {
      userStore.getState().setIsPaidUser();
    }
  } catch (error) {
    logError('usePurchases -> restorePurchase', error);
  }
};

export const usePurchases = () => {
  const [offerings, setOfferings] = useState<PurchasesOffering>();
  const [isPurchaseInProgress, setIsPurchaseInProgress] = useState(false);

  const makePurchase = useCallback(async () => {
    if (!offerings?.availablePackages[0]) return;

    setIsPurchaseInProgress(true);
    try {
      const purchaseMade = await Purchases.purchasePackage(offerings?.availablePackages[0]);
      if (typeof purchaseMade.customerInfo.entitlements.active.premium !== 'undefined') {
        userStore.getState().setIsPaidUser();
      }
    } catch (error) {
      logError('usePurchases -> makePurchase', error);
    } finally {
      setIsPurchaseInProgress(false);
    }
  }, [offerings?.availablePackages]);

  const getOfferings = useCallback(async () => {
    try {
      const res = await Purchases.getOfferings();
      if (res.current !== null && res.current.availablePackages.length !== 0) {
        setOfferings(res.current);
      }
    } catch (error) {
      logError('usePurchases -> getOfferings', error);
    }
  }, []);

  useEffect(() => {
    getOfferings();
  }, [getOfferings]);

  return { offerings, makePurchase, isPurchaseInProgress };
};
