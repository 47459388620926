import { useQuery } from '@tanstack/react-query';
import { DailyTaskType, type DailyStatus, type DailyTask } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';
import { DAILY_STATUS } from './queryKeys';

const fetchDailyStatus = async () => {
  const dailyTasksUrl = 'stats/v2/dailyStatus';
  const intakeFormTaskUrl = 'stats/intake-form';
  let intakeFormTask;

  try {
    const { task } = await api.get(intakeFormTaskUrl).json<{ task: DailyTask }>();
    intakeFormTask = task;
  } catch (error) {
    logError(`GET -> ${intakeFormTaskUrl}`, error);
  }

  try {
    const status = await api.get(dailyTasksUrl).json<DailyStatus>();

    const tasksWithKnowType = status.tasks.filter((t) =>
      Object.values(DailyTaskType).includes(t.task),
    );
    const comepletedTasks = tasksWithKnowType.filter((task) => task.isComplete);
    const notCompletedTasks = tasksWithKnowType
      .filter((task) => !task.isComplete)
      .sort((a, b) => a.priority - b.priority);

    return {
      ...status,
      tasks: [
        ...(intakeFormTask ? [intakeFormTask] : []),
        ...comepletedTasks,
        ...notCompletedTasks,
      ],
    };
  } catch (error) {
    logError(`GET -> ${dailyTasksUrl}`, error);
    return null;
  }
};

export const useGetDailyStatusQuery = () => useQuery([DAILY_STATUS], fetchDailyStatus);
