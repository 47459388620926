import type { OnboardingAnswer } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';

export const updateOnboardingData = async (payload: {
  fromSlug: string;
  data: OnboardingAnswer[];
}) => {
  const url = 'onboarding/survey';

  try {
    await api.put(url, { json: payload });
  } catch (error) {
    logError(`PUT -> ${url}`, error);
  }
};
