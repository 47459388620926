import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../theme/constants';

type Props = {
  count: number;
  current: number;
};

export const ProgressIndicator: React.FC<Props> = ({ count, current }) => {
  return (
    <View style={styles.container}>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <View
            key={index}
            style={[
              styles.item,
              {
                backgroundColor: current > index ? colors.orange600 : colors.orange300,
                marginRight: count - 1 === index ? 0 : 8,
              },
            ]}
          />
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 20,
    width: 238,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    height: '25%',
    flex: 1,
    borderRadius: 10,
  },
});
