import type { StackNavigationProp } from '@react-navigation/stack';
import { DailyTaskType, type DailyTask } from '../global/types';
import type { AuthenticatedStackParams, ProgramStackParams } from '../navigation/types';
import { useProgramStore as programStore } from '../store/useProgramStore';
import { shouldBeUnreachable } from './common';

export const enreachDailyTaskData = (
  dailyTask: DailyTask,
  navigation: StackNavigationProp<AuthenticatedStackParams & ProgramStackParams> | null,
  isPaidUser: boolean,
) => {
  const setCurrentProgramId = programStore.getState().setCurrentProgramId;

  switch (dailyTask.task) {
    case DailyTaskType.IntakeForm:
      return {
        image: 'coach.png',
        action: () => {
          setCurrentProgramId(dailyTask.formUid as string);
          navigation?.navigate(isPaidUser ? 'ProgramStack' : 'Payment');
        },
      };
    case DailyTaskType.IntroToAdhd:
      return {
        image: 'video-call.png',
        action: () => {
          setCurrentProgramId(dailyTask.programUid as string);
          navigation?.navigate(isPaidUser ? 'ProgramStack' : 'Payment');
        },
      };
    case DailyTaskType.DailyProgram:
      return {
        image: 'learning.png',
        action: () => {
          setCurrentProgramId(dailyTask.programUid as string);
          navigation?.navigate(isPaidUser ? 'ProgramStack' : 'Payment');
        },
      };
    case DailyTaskType.HowYouFeel:
      return {
        image: 'inspiration.png',
        action: () => navigation?.navigate(isPaidUser ? 'ChooseFeeling' : 'Payment'),
      };
    default:
      shouldBeUnreachable(dailyTask.task);
  }
};
