import { useAuth0 } from '@auth0/auth0-react';
import { useNavigation } from '@react-navigation/native';
import * as Sentry from '@sentry/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Drawer from 'react-modern-drawer';
import { Image, StyleSheet, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { Button } from '../../src/components/Button';
import { Text } from '../../src/components/Text';
import { UpgradeBanner } from '../../src/components/UpgradeBanner';
import {
  AgaveLogo,
  AvatarIcon,
  ChatIconFilled,
  HomeIcon,
  MenuIcon,
} from '../../src/components/icons';
import { APP_STORE_URL, PLAY_STORE_URL } from '../../src/global/constants';
import { SubscriptionStatus } from '../../src/global/types';
import { webBreakpoints } from '../../src/theme/constants';
import { DrawerItem } from './DrawerItem';

const drawerLinks = {
  home: '/home',
  chat: '/chat',
  settings: '/settings',
};

type Props = {
  children: React.ReactNode;
  subscriptionStatus: SubscriptionStatus | undefined;
};

export const WithDrawer: React.FC<Props> = ({ children, subscriptionStatus }) => {
  const { logout } = useAuth0();
  const navigation = useNavigation();
  const [activePageLink, setActivePageLink] = useState(drawerLinks.home);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isDrawerOpenManually, setIsDrawerOpenManually] = useState(false);
  const { width: pageWidth } = useWindowDimensions();
  const isSmallWidth = pageWidth < webBreakpoints.md;

  useLayoutEffect(() => {
    setIsDrawerOpen(!isSmallWidth || isDrawerOpenManually);
  }, [isDrawerOpenManually, isSmallWidth]);

  useEffect(() => {
    setTimeout(() => {
      const urlArr = window.location.href.split('/');
      setActivePageLink(`/${urlArr[urlArr.length - 1]}`);
    }, 1000);
  }, []);

  useEffect(() => {
    navigation.setOptions({
      headerShown: isSmallWidth,
      headerTitle: () => (
        <TouchableOpacity onPress={() => setIsDrawerOpenManually((prev) => !prev)}>
          <MenuIcon />
        </TouchableOpacity>
      ),
    });
  }, [isSmallWidth, navigation]);

  const handleLogOut = () => {
    Sentry.setUser(null);
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
      <Drawer
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setIsDrawerOpenManually(false);
        }}
        direction='left'
        enableOverlay={isSmallWidth}
        style={{
          padding: 20,
          paddingTop: isSmallWidth ? 50 : 0,
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <AgaveLogo />
        </View>
        <DrawerItem
          text='Home'
          activeLink={activePageLink}
          link={drawerLinks.home}
          icon={<HomeIcon />}
          onPress={() => setActivePageLink(drawerLinks.home)}
        />
        <DrawerItem
          text='Chat'
          activeLink={activePageLink}
          link={drawerLinks.chat}
          icon={<ChatIconFilled />}
          onPress={() => setActivePageLink(drawerLinks.chat)}
        />
        <View style={{ marginLeft: 5 }}>
          <DrawerItem
            text='Settings'
            activeLink={activePageLink}
            link={drawerLinks.settings}
            icon={<AvatarIcon size='sm' />}
            onPress={() => setActivePageLink(drawerLinks.settings)}
          />
        </View>
        <View style={{ position: 'absolute', bottom: isSmallWidth ? '10%' : '3%', gap: 30 }}>
          {isMobile ? (
            <View style={styles.storeLinksContainer}>
              <a href={APP_STORE_URL} target='_blank' rel='noopener norefferer'>
                <Image
                  source={require('../../src/assets/images/app-store.png')}
                  style={{ width: 130, height: 45 }}
                />
              </a>
              <a href={PLAY_STORE_URL} target='_blank' rel='noopener norefferer'>
                <Image
                  source={require('../../src/assets/images/play-store.png')}
                  style={{ width: 130, height: 45 }}
                />
              </a>
            </View>
          ) : (
            <View style={{ gap: 16 }}>
              <Text variant='h3' style={{ textAlign: 'center' }}>
                Download mobile app
              </Text>
              <Image
                source={require('../../src/assets/images/app-download-qr-code.png')}
                style={{ width: 150, height: 190, alignSelf: 'center' }}
              />
            </View>
          )}
          <Button title='Logout' onPress={handleLogOut} />
        </View>
      </Drawer>
      <View style={{ flex: 1, marginLeft: isSmallWidth ? 0 : 290 }}>
        {subscriptionStatus !== SubscriptionStatus.PREMIUM ? <UpgradeBanner /> : null}
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  storeLinksContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
