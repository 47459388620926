import type { Channel } from 'stream-chat';
import { create } from 'zustand';
import { getChatClient, logError } from '../utils/common';

type ChatStore = {
  channel: Channel | null;
  setChannel: (channel: Channel) => void;
  getRemoteChannel: (id: string) => Promise<Channel | undefined>;
};

const chatClient = getChatClient();

export const useChatStore = create<ChatStore>()((set, get) => ({
  channel: null,
  setChannel: (channel) => set({ channel }),
  getRemoteChannel: async (id) => {
    try {
      const channels = await chatClient.queryChannels({ id });
      get().setChannel(channels[0]);
      return channels[0];
    } catch (error) {
      logError('useChatStore -> getRemoteChannel', error);
    }
  },
}));
