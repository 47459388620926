import ky from 'ky';
import { api } from '../../src/api/config';
import { logError } from '../../src/utils/common';

export const uploadImage = async (file: File) => {
  const url = 'files/uploadUrl';

  try {
    const { previewUrl, uploadUrl } = await api
      .post(url, { json: { filename: file.name.replaceAll(' ', '_') } })
      .json<{ previewUrl: string; uploadUrl: string }>();

    const blob = new Blob([file], { type: 'image/png,image/jpeg' });
    ky.put(uploadUrl, { body: blob }).text();

    return previewUrl;
  } catch (error) {
    logError('uploadFile', error);
  }
};
