import React from 'react';
import { Image, View, type ImageURISource, type StyleProp, type ViewStyle } from 'react-native';
import { colors } from '../theme/constants';
import { Text } from './Text';

type Props = {
  size: number;
  imageSrc: ImageURISource;
  fallbackText?: string;
  fallbackIcon?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

export const Avatar: React.FC<Props> = ({ size, imageSrc, fallbackText, fallbackIcon, style }) => {
  return (
    <View
      style={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor: !fallbackIcon ? colors.orange600 : 'transparent',
        },
        style,
      ]}
    >
      {imageSrc?.uri ? (
        <Image
          source={imageSrc}
          style={{
            width: size,
            height: size,
            borderRadius: size,
            position: 'absolute',
            zIndex: 1,
          }}
        />
      ) : fallbackIcon ? (
        fallbackIcon
      ) : fallbackText ? (
        <Text variant='button' style={{ fontSize: size / 3, color: colors.white100 }}>
          {fallbackText}
        </Text>
      ) : null}
    </View>
  );
};
