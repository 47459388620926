import { useQuery } from '@tanstack/react-query';
import { type AppPrice } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';
import { PRICES } from './queryKeys';

const fetchAppPrices = async () => {
  const url = 'checkout/prices';
  try {
    return await api.get(url).json<AppPrice>();
  } catch (error) {
    logError(`GET -> ${url}`, error);
    return null;
  }
};

export const useGetPricesQuery = () => useQuery([PRICES], fetchAppPrices);
