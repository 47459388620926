import type { ReactElement } from 'react';
import React from 'react';
import { StyleSheet, View, type StyleProp, type ViewStyle } from 'react-native';
import { colors } from '../theme/constants';

type Props = {
  children: ReactElement;
  style?: StyleProp<ViewStyle>;
};

export const Card: React.FC<Props> = ({ children, style }) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white100,
    borderRadius: 8,
    shadowOffset: { width: 0, height: 16 },
    shadowRadius: 16,
    shadowColor: colors.orange600,
    shadowOpacity: 0.08,
  },
});
