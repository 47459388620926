import React, { type ChangeEvent } from 'react';
import { StyleSheet, View } from 'react-native';
import { ChevronDownIcon } from '../../src/components/icons';
import { colors } from '../../src/theme/constants';

type Props = {
  items: string[];
  onValueChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  placeholder?: string;
};

export const Select: React.FC<Props> = ({
  value,
  items,
  onValueChange,
  placeholder = 'Choose here',
}) => {
  return (
    <View>
      {/*// @ts-ignore */}
      <select style={styles.container} value={value} onChange={onValueChange}>
        <option value=''>{placeholder}</option>
        {items.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
      <View style={{ position: 'absolute', right: 12, top: 7, zIndex: -1 }}>
        <ChevronDownIcon />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: colors.grey100,
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: 'transparent',
    height: 38,
    paddingLeft: 8,
    paddingVertical: 8,
    fontFamily: 'Catamaran',
    fontSize: 16,
    color: colors.black600,
    // @ts-ignore
    outlineWidth: 0,
    appearance: 'none',
  },
});
