export const CURRENT_USER = 'currentUser';
export const USER_ADDRESS = 'user-address';
export const PROGRAMS_LIST = 'programsList';
export const EVENTS_LIST = 'eventsList';
export const NEWS_LIST = 'newsList';
export const USER = 'user';
export const USER_BY_ID = 'userById';
export const USER_BY_TEXT = 'userByText';
export const NOTIFICATION_TASK = 'notificationTask';
export const DAILY_STATUS = 'dailyStatus';
export const STATS_OVERVIEW = 'statsOverview';
export const STATS_CHECKINS = 'statsCheckins';
export const MEETING_STATUSES = 'meetingStatuses';
export const COACHES_LIST = 'coachesList';
export const USER_CHANNELS_LIST = 'userChannelsList';
export const PRICES = 'prices';
export const PROGRAM = 'program';
export const THERAPY_COURSE_LIST = 'therapyCourseList';
export const THERAPY_COURSE = 'therapyCourse';
export const THERAPISTS_LIST = 'therapistsList';
export const THERAPY = 'therapy';
export const ONBOARDING_STATUS = 'onboardingStatus';
