import type { ProgramAnswers } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';

export const updateProgram = async (
  programId: string,
  slug: string,
  programAnswers: ProgramAnswers,
) => {
  const url = `activity/cbt/${programId}`;

  try {
    await api.put(url, { json: { fromSlug: slug, data: programAnswers } });
  } catch (error) {
    logError(`PUT -> ${url}`, error);
  }
};
