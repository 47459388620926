import { useMutation } from '@tanstack/react-query';
import { logError } from '../utils/common';
import { api } from './config';

const switchCoach = async (coachId: string) => {
  const url = 'chat/me_switch_coach';
  try {
    await api.put(url, { json: { coachId } });
  } catch (error) {
    logError(`PUT -> ${url}`, error);
    throw error;
  }
};

export const useSwitchCoachMutation = () => {
  return useMutation((coachId: string) => switchCoach(coachId));
};
