import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../theme/constants';
import { Text } from './Text';
import { FireIcon } from './icons';

export const RecommendedBadge = ({ isLarge = false }: { isLarge?: boolean }) => {
  return (
    <View
      style={[
        styles.container,
        {
          paddingRight: isLarge ? 8 : 0,
          paddingLeft: isLarge ? 4 : 0,
        },
      ]}
    >
      <FireIcon />
      {isLarge && (
        <Text variant='body3' style={{ color: colors.orange600, fontWeight: '700', marginLeft: 4 }}>
          Recommended Program
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    backgroundColor: colors.orange300,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.orange600,
  },
});
