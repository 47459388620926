import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { MessageTimestamp, useMessageContext } from 'stream-chat-react';
import { Text } from '../../../../src/components/Text';
import type { DailyCheckIn } from '../../../../src/global/types';
import { colors } from '../../../../src/theme/constants';
import { DailyCheckInAnswersModal } from './DailyCheckInAnswersModal';

export const DailyCheckInAnswersMessage = () => {
  const { message } = useMessageContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity onPress={() => setIsModalOpen(true)}>
          <View style={styles.innerContainer}>
            <Text variant='body1' style={{ color: colors.blue600 }}>
              {message.text}
            </Text>
          </View>
        </TouchableOpacity>
        <Text variant='body2' style={styles.timeStamp}>
          <MessageTimestamp />
        </Text>
      </View>
      {isModalOpen && (
        <DailyCheckInAnswersModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={message.attachments?.[0]?.data as DailyCheckIn}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 4,
    position: 'relative',
    right: '-80%',
    maxWidth: '20%',
  },
  innerContainer: {
    paddingBottom: 8,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingTop: 12,
    backgroundColor: colors.green200,
  },
  timeStamp: {
    lineHeight: 25,
    color: '#72767e',
    position: 'relative',
    right: '-80%',
  },
});
