import type { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useRef, useState } from 'react';
import {
  Linking,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import { toast } from 'react-toastify';
import { useGetUserQuery } from '../../src/api/useGetUserQuery';
import { useUpdateUserMutation } from '../../src/api/useUpdateUserMutation';
import { Avatar } from '../../src/components/Avatar';
import { Button } from '../../src/components/Button';
import { Input } from '../../src/components/Input';
import { Text } from '../../src/components/Text';
import { AvatarIcon, EditIcon } from '../../src/components/icons';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../src/global/constants';
import { UserGender } from '../../src/global/types';
import { useManageMembership } from '../../src/hooks/useManageMembership';
import { SettingsRow } from '../../src/screens/Settings/components/SettingsRow';
import { colors, webBreakpoints } from '../../src/theme/constants';
import { openSupportMail } from '../../src/utils/common';
import { uploadImage } from '../api/uploadImage';
import { Modal } from '../components/Modal';
import { Select } from '../components/Select';
import type { DrawerParams } from '../navigation/types';

type Props = StackScreenProps<DrawerParams, 'Settings'>;

export const Settings: React.FC<Props> = () => {
  const { width: pageWidth } = useWindowDimensions();
  const imageUploadRef = useRef<HTMLInputElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: userData, isLoading: isUserDataLoading } = useGetUserQuery();
  const [avatarPath, setAvatarPath] = useState(userData?.me.avatarUrl);
  const [firstName, setFirstName] = useState(userData?.me.firstName || '');
  const [lastName, setLastName] = useState(userData?.me.lastName || '');
  const [gender, setGender] = useState<UserGender | undefined>(userData?.me.gender || undefined);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const { shouldShowSubscription, handleMembershipPress } = useManageMembership();
  const {
    mutate: updateUser,
    isLoading: isUpdateUserLoading,
    isSuccess: isUpdateUserSuccess,
    isError: isUpdateUserError,
  } = useUpdateUserMutation();

  useEffect(() => {
    if (isUpdateUserSuccess) {
      toast('Profile updated!', { type: 'success', position: 'top-right' });
    } else if (isUpdateUserError) {
      toast('Something went wrong', { type: 'error' });
    }
  }, [isUpdateUserError, isUpdateUserSuccess]);

  const removePhoto = async () => {
    setAvatarPath(undefined);
    setIsModalOpen(false);
  };

  const uploadProfilePhoto = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setAvatarPath(URL.createObjectURL(event.target.files[0]));
      const previewUrl = await uploadImage(event.target.files[0]);
      setImageUrl(previewUrl);
    }
  };

  return (
    <ScrollView style={{ flex: 1, backgroundColor: colors.beige100 }}>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contentStyle={{ width: pageWidth < webBreakpoints.sm ? 'auto' : 300, height: 120 }}
      >
        <View style={{ gap: 16, alignItems: 'center', marginTop: 15 }}>
          <Button
            title='Upload profile photo'
            onPress={() => {
              imageUploadRef.current?.click();
              setIsModalOpen(false);
            }}
          />
          <Button variant='text' title='Remove profile photo' onPress={removePhoto} />
        </View>
      </Modal>
      <View style={styles.profileDetailsContainer}>
        <Text variant='h2'>Profile details</Text>
        <View style={styles.form}>
          <TouchableOpacity
            onPress={() => setIsModalOpen(true)}
            style={{ alignItems: 'center', width: '100%', maxWidth: 500 }}
          >
            <View style={styles.editIconWrapper}>
              <EditIcon />
            </View>
            <Avatar
              imageSrc={{ uri: avatarPath }}
              fallbackIcon={<AvatarIcon size='lg' />}
              size={96}
            />
            <input
              type='file'
              style={{ display: 'none' }}
              ref={imageUploadRef}
              onChange={uploadProfilePhoto}
              accept='image/png,image/jpeg'
            />
          </TouchableOpacity>
          <View style={{ gap: 4 }}>
            <Text variant='body1'>Email</Text>
            <Text variant='button'>{userData?.me.email}</Text>
          </View>
          <View style={{ gap: 4 }}>
            <Text variant='body1'>First Name</Text>
            <Input value={firstName} onChangeText={setFirstName} />
          </View>
          <View style={{ gap: 4 }}>
            <Text variant='body1'>Last Name</Text>
            <Input value={lastName} onChangeText={setLastName} />
          </View>
          <View style={{ gap: 4 }}>
            <Text variant='body1'>Gender</Text>
            <Select
              value={gender}
              items={Object.values(UserGender)}
              onValueChange={(event) => setGender(event.target.value as UserGender)}
            />
          </View>
          <Button
            title='Save'
            isLoading={isUpdateUserLoading}
            isDisabled={isUserDataLoading}
            onPress={() =>
              updateUser({ ...userData?.me, firstName, lastName, gender, avatarUrl: imageUrl })
            }
            style={{ marginTop: 20, marginBottom: 50, width: 120 }}
          />
          {shouldShowSubscription && (
            <SettingsRow title='Membership' onPress={handleMembershipPress} />
          )}
          <SettingsRow
            title='Terms of service'
            onPress={() => Linking.openURL(TERMS_AND_CONDITIONS_URL)}
          />
          <SettingsRow title='Privacy policy' onPress={() => Linking.openURL(PRIVACY_POLICY_URL)} />
          <SettingsRow title='Support' onPress={openSupportMail} />
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  profileDetailsContainer: {
    flex: 1,
    paddingVertical: 50,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  form: {
    gap: 16,
    width: '100%',
    maxWidth: 500,
  },
  editIconWrapper: {
    zIndex: 1,
    backgroundColor: colors.white100,
    borderRadius: 100,
    transform: [{ translateX: 35 }, { translateY: 30 }],
  },
});
