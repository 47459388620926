import React, { useMemo } from 'react';
import { View } from 'react-native';
import { useGetUserProgramByIdQuery } from '../../../../src/api/useGetUserProgramByIdQuery';
import { Text } from '../../../../src/components/Text';
import { colors } from '../../../../src/theme/constants';
import { safeParseJSON } from '../../../../src/utils/common';
import { Loading } from '../../../components/Loading';
import { Modal } from '../../../components/Modal';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  programUid: string;
  lessonSlug: string;
  userId: string;
};

export const CbtLessonAnswersModal: React.FC<Props> = ({
  isModalOpen,
  onClose,
  programUid,
  lessonSlug,
  userId,
}) => {
  const { data, isLoading } = useGetUserProgramByIdQuery({ programId: programUid, userId });
  const { program, answers } = data || {};

  const lessonTitle = useMemo(() => {
    const lesson = program?.lessons.find((l) => l.slug === lessonSlug);
    return lesson?.title;
  }, [lessonSlug, program?.lessons]);

  const viewData = useMemo(() => {
    const dataArr: {
      title: string;
      answers: string[];
    }[] = [];

    if (!program || !answers) return [];

    const lesson = program.lessons.find((l) => l.slug === lessonSlug);

    if (!lesson) return [];

    Object.entries(lesson.steps).forEach(([stepSlug, step]) => {
      const stepAnswers = answers?.lessons?.[lessonSlug]?.steps?.[stepSlug]?.answers;
      if (stepAnswers) {
        dataArr.push({
          title: step.title as string,
          answers: stepAnswers,
        });
      }
    });

    return dataArr;
  }, [answers, lessonSlug, program]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      contentStyle={{ maxWidth: 600, maxHeight: 600, backgroundColor: colors.beige100 }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <View style={{ gap: 16 }}>
          <Text variant='h3' style={{ textAlign: 'center', color: colors.orange600 }}>
            {program?.title}: {lessonTitle}
          </Text>
          {viewData.map((item, index) => (
            <View key={index}>
              <Text variant='button'>{item.title}</Text>
              {item.answers.map((answer, i) => {
                const parsedAnswer: { title: string } | string = safeParseJSON(answer);
                return (
                  <Text key={i} variant='body1'>
                    {typeof parsedAnswer === 'object' && parsedAnswer !== null
                      ? parsedAnswer.title
                      : parsedAnswer}
                  </Text>
                );
              })}
            </View>
          ))}
        </View>
      )}
    </Modal>
  );
};
