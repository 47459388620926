import type BottomSheet from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { Linking, View } from 'react-native';
import { useGetMeetingStatusesQuery } from '../../src/api/useGetMeetingStatusesQuery';
import { useGetUserQuery } from '../../src/api/useGetUserQuery';
import { Avatar } from '../../src/components/Avatar';
import { Button } from '../../src/components/Button';
import { Card } from '../../src/components/Card';
import { Text } from '../../src/components/Text';
import { MeetingCta } from '../../src/global/types';
import type { AuthenticatedStackParams } from '../../src/navigation/types';
import { getFullCalendarUrl } from '../../src/utils/common';

export const CoachDetails = () => {
  const bottomSheetRef = useRef<BottomSheet>(null);
  const navigation = useNavigation<StackNavigationProp<AuthenticatedStackParams>>();
  const { data: meetingStatuses, refetch: refetchMeetingsStatus } =
    useGetMeetingStatusesQuery('me');
  const { data: userData } = useGetUserQuery();
  const { firstName: coachFirstName, avatarUrl, description, calendarUrl } = userData?.coach || {};
  const meetingStatus = meetingStatuses?.find((s) => s.type === 'regular');

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refetchMeetingsStatus();
    });
    return unsubscribe;
  }, [navigation, refetchMeetingsStatus]);

  return (
    <View style={{ alignItems: 'center', paddingTop: 24, paddingHorizontal: 16, marginBottom: 40 }}>
      <Avatar imageSrc={{ uri: avatarUrl }} size={160} />
      <Text variant='h1' style={{ marginBottom: 40, marginTop: 16, textAlign: 'center' }}>
        Coach {coachFirstName}
      </Text>
      <Card style={{ padding: 24, width: '100%', elevation: 2 }}>
        <>
          <Text variant='h3' style={{ marginBottom: 8 }}>
            About
          </Text>
          <Text variant='body1'>{description}</Text>
        </>
      </Card>
      {calendarUrl && (
        <Button
          title={
            meetingStatus?.event
              ? `Call scheduled - ${dayjs(meetingStatus.event.startTime).format(
                  'ddd, MMM DD, h:mmA',
                )}`
              : `Book a call with ${coachFirstName}`
          }
          isDisabled={!!meetingStatus?.event}
          onPress={() =>
            Linking.openURL(
              getFullCalendarUrl({
                baseUrl: calendarUrl,
                user: userData?.me,
              }),
            )
          }
          style={{ width: meetingStatus?.event ? 'auto' : 240, marginTop: 48 }}
        />
      )}
      {meetingStatus?.cta === MeetingCta.SCHEDULED && (
        <Button
          title='Reschedule or cancel here'
          variant='outlined'
          onPress={() => bottomSheetRef.current?.expand()}
          style={{ marginTop: 16 }}
        />
      )}
    </View>
  );
};
