import { useEffect, useMemo, useState } from 'react';
import { Linking } from 'react-native';
import { getStripeCustomerPortalUrl } from '../api/getStripeCustomerPortalUrl';
import { useGetUserQuery } from '../api/useGetUserQuery';
import { SubscriptionStatus } from '../global/types';
import { useUserStore } from '../store/useUserStore';
import { logError } from '../utils/common';
import { hasPaidWithApplePay } from './usePurchases';

export const useManageMembership = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: userData } = useGetUserQuery();
  const isPaidUser = useUserStore((state) => state.isPaidUser);
  const [stripePortalUrl, setStripePortalUrl] = useState<string | undefined>();
  const [paidWithApplePay, setPaidWithApplePay] = useState<boolean | undefined>();
  const shouldShowSubscription = useMemo(() => {
    return (
      isPaidUser &&
      !(userData?.me.subscriptionStatus === SubscriptionStatus.TRIAL) &&
      !userData?.me.clientCompany
    );
  }, [isPaidUser, userData?.me.clientCompany, userData?.me.subscriptionStatus]);

  useEffect(() => {
    if (!shouldShowSubscription) return;

    (async () => {
      try {
        setIsLoading(true);
        const isApplePay = await hasPaidWithApplePay();
        setPaidWithApplePay(isApplePay);
        if (!isApplePay) {
          const portalUrl = await getStripeCustomerPortalUrl();
          setStripePortalUrl(portalUrl);
        }
      } catch (error) {
        logError('AccountScreen', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [shouldShowSubscription]);

  const handleMembershipPress = () => {
    if (paidWithApplePay) {
      Linking.openURL('https://apps.apple.com/account/subscriptions');
    } else if (stripePortalUrl) {
      Linking.openURL(stripePortalUrl);
    }
  };

  return {
    isLoading,
    shouldShowSubscription,
    handleMembershipPress,
    stripePortalUrl,
  };
};
