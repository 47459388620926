import { logError } from '../utils/common';
import { api } from './config';

export const startTrial = async () => {
  const url = 'user/me/trial';

  try {
    await api.post(url, {});
  } catch (error) {
    logError(`POST -> ${url}`, error);
  }
};
