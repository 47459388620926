import { logError } from '../utils/common';
import { api } from './config';

export const getStripeCustomerPortalUrl = async () => {
  const url = 'checkout/customer-portal';
  try {
    const { url: portalUrl } = await api.post(url).json<{ url: string }>();
    return portalUrl;
  } catch (error) {
    logError(`POST -> ${url}`, error);
  }
};
