import { logError } from '../utils/common';
import { api } from './config';

export const getStripeCheckoutUrl = async (id?: string) => {
  const url = `checkout/create-checkout-session/${id ? id : ''}`;
  try {
    const { checkoutUrl } = await api.get(url).json<{ checkoutUrl: string }>();
    return checkoutUrl;
  } catch (error) {
    logError(`GET -> ${url}`, error);
  }
};
