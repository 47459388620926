import React from 'react';
import { StyleSheet, View } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { MessageTimestamp, useMessageContext } from 'stream-chat-react';
import { Text } from '../../../../src/components/Text';
import { colors } from '../../../../src/theme/constants';

export const BotMessage = () => {
  const { message, isMyMessage } = useMessageContext();

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.innerContainer,
          {
            borderBottomRightRadius: isMyMessage() ? 0 : 16,
            borderBottomLeftRadius: isMyMessage() ? 16 : 0,
          },
        ]}
      >
        <Hyperlink linkDefault injectViewProps={() => ({ style: { color: colors.blue600 } })}>
          <Text variant='body1'>{message.text}</Text>
        </Hyperlink>
      </View>
      <Text variant='body2' style={{ lineHeight: 25, color: '#72767e' }}>
        <MessageTimestamp />
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 4,
    maxWidth: '50%',
  },
  innerContainer: {
    paddingBottom: 8,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingTop: 12,
    backgroundColor: colors.orange300,
  },
});
