import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from '../../../components/Text';
import { ChevronRightIcon } from '../../../components/icons';
import { colors } from '../../../theme/constants';

type Props = {
  icon?: React.ReactElement;
  title: string;
  hideArrow?: boolean;
  onPress: () => void;
  unreadCount?: number;
};

export const SettingsRow: React.FC<Props> = ({
  icon: Icon,
  title,
  onPress,
  hideArrow = false,
  unreadCount = 0,
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.container]}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {Icon && Icon}
        {unreadCount > 0 && (
          <View style={styles.unreadCount}>
            <Text
              style={{ fontSize: 8, color: colors.white100, textAlign: 'center', lineHeight: 12 }}
            >
              {unreadCount}
            </Text>
          </View>
        )}
        <Text variant='h3' style={{ marginLeft: Icon ? 16 : 0 }}>
          {title}
        </Text>
      </View>
      {!hideArrow && <ChevronRightIcon />}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 0.5,
    height: 60,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
    borderBottomColor: colors.orange400,
  },
  unreadCount: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.red500,
    borderRadius: 6,
    position: 'absolute',
    left: 12,
    top: 4,
    height: 12,
    width: 12,
  },
});
