import { create } from 'zustand';

type UserStore = {
  authToken: string | undefined;
  hasAuthError: boolean;
  isPaidUser: boolean;
  unreadMsgCount: number;
  hasMarkedMessageAsUnread: boolean;
  setAuthToken: (token: string) => void;
  setHasAuthError: () => void;
  setIsPaidUser: () => void;
  setUnreadMsgCount: (count: number) => void;
  setHasMarkedMessageAsUnread: (isMarked: boolean) => void;
};

export const useUserStore = create<UserStore>()((set) => ({
  authToken: undefined,
  hasAuthError: false,
  isPaidUser: false,
  unreadMsgCount: 0,
  hasMarkedMessageAsUnread: false,
  setHasMarkedMessageAsUnread: (isMarked) => set({ hasMarkedMessageAsUnread: isMarked }),
  setAuthToken: (token) => set({ authToken: token }),
  setHasAuthError: () => set({ hasAuthError: true }),
  setIsPaidUser: () => set({ isPaidUser: true }),
  setUnreadMsgCount: (count) => set({ unreadMsgCount: count }),
}));
