import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { MessageTimestamp, useMessageContext } from 'stream-chat-react';
import { Text } from '../../../../src/components/Text';
import { colors } from '../../../../src/theme/constants';
import { CbtLessonAnswersModal } from './CbtLessonAnswersModal';

export const CbtLessonAnswersMessage = () => {
  const { message } = useMessageContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity onPress={() => setIsModalOpen(true)}>
          <View style={styles.innerContainer}>
            <Text variant='body1' style={{ color: colors.blue600 }}>
              {message.text}
            </Text>
          </View>
        </TouchableOpacity>
        <Text variant='body2' style={styles.timeStamp}>
          <MessageTimestamp />
        </Text>
      </View>
      {isModalOpen && (
        <CbtLessonAnswersModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          lessonSlug={message.attachments?.[0]?.lesson_slug as string}
          programUid={message.attachments?.[0]?.program_uid as string}
          userId={message.attachments?.[0]?.user_id as string}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 4,
    position: 'relative',
    right: '-50%',
    maxWidth: '50%',
  },
  innerContainer: {
    paddingBottom: 8,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingTop: 12,
    backgroundColor: colors.green200,
  },
  timeStamp: {
    lineHeight: 25,
    color: '#72767e',
    position: 'relative',
    right: '-90%',
  },
});
