import { create } from 'zustand';
import type { Coach, Therapy, TherapyCourse } from '../global/types';

type TherapyStore = {
  usState: string;
  setUsState: (state: string) => void;
  therapist: Coach | null;
  therapyCourse: TherapyCourse | null;
  therapy: Therapy | null;
  setTherapist: (therapist: Coach) => void;
  setTherapyCourse: (course: TherapyCourse) => void;
  setTherapy: (therapy: Therapy) => void;
};

export const useTherapyStore = create<TherapyStore>()((set) => ({
  usState: '',
  therapist: null,
  therapyCourse: null,
  therapy: null,
  setUsState: (state) => set({ usState: state }),
  setTherapist: (therapist) => set({ therapist }),
  setTherapyCourse: (therapyCourse) => set({ therapyCourse }),
  setTherapy: (therapy) => set({ therapy }),
}));
