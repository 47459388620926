import { useQuery } from '@tanstack/react-query';
import type { Program, ProgramAnswers } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';
import { PROGRAM } from './queryKeys';

export const fetchUserProgramById = async ({
  programId,
  userId,
}: {
  programId: string;
  userId: string;
}) => {
  const url = `activity/cbt/program/${programId}/user/${userId}`;

  try {
    const programData = await api.get(url).json<{ program: Program; answers: ProgramAnswers }>();
    return programData;
  } catch (error) {
    logError(`GET -> ${url}`, error);
    return null;
  }
};

export const useGetUserProgramByIdQuery = ({
  programId,
  userId,
}: {
  programId: string;
  userId: string;
}) =>
  useQuery([PROGRAM, programId], () => fetchUserProgramById({ programId, userId }), {
    enabled: !!programId && !!userId,
  });
