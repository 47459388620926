import { useQuery } from '@tanstack/react-query';
import type { MeetingStatus } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';
import { MEETING_STATUSES } from './queryKeys';

// pass userId to fetch meeting status for that user or pass 'me' to fetch meeting status for current user
const fetchMeetingStatuses = async (userId: string) => {
  const url = `meetings/v2/${userId}`;
  try {
    const { data } = await api.get(url).json<{ data: MeetingStatus[] }>();
    return data;
  } catch (error) {
    logError(`GET -> ${url}`, error);
    return null;
  }
};

export const useGetMeetingStatusesQuery = (userId: string, refetchInterval?: number) =>
  useQuery([MEETING_STATUSES], () => fetchMeetingStatuses(userId), {
    enabled: !!userId,
    refetchInterval: refetchInterval || false,
  });
