import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { colors } from '../../src/theme/constants';

export const Loading = () => {
  return (
    <View style={styles.container}>
      <ActivityIndicator size={100} color={colors.orange600} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.beige100,
  },
});
