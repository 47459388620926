import type { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { toast } from 'react-toastify';
import { useGetCoachesListQuery } from '../../src/api/useGetCoachesListQuery';
import { useSwitchCoachMutation } from '../../src/api/useSwitchCoachMutation';
import { Avatar } from '../../src/components/Avatar';
import { Button } from '../../src/components/Button';
import { Card } from '../../src/components/Card';
import { Text } from '../../src/components/Text';
import { colors, webBreakpoints } from '../../src/theme/constants';
import { Loading } from '../components/Loading';
import { Modal } from '../components/Modal';
import type { AuthenticatedStackParams } from '../navigation/types';

type Props = StackScreenProps<AuthenticatedStackParams, 'SwitchCoach'>;

export const SwitchCoach: React.FC<Props> = ({ navigation, route }) => {
  const { width: pageWidth } = useWindowDimensions();
  const {
    mutate: switchCoach,
    isLoading: isSwitchCoachLoading,
    isSuccess: isSwitchCoachSuccess,
    isError: isSwitchCoachError,
  } = useSwitchCoachMutation();
  const { data: coachesList, isLoading: isCoachesListLoading } = useGetCoachesListQuery();
  const activityName = route.params?.activityName;
  const [currentIndex, setCurrentIndex] = useState(0);
  const isOnboarding = activityName?.includes('onboarding');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isSwitchCoachSuccess && isOnboarding) {
      setIsModalOpen(false);
      toast(`Congrats! Your new coach is ${coachesList?.[currentIndex].firstName}`, {
        type: 'success',
      });
      navigation.navigate('Payment');
    } else if (isSwitchCoachError) {
      toast('Something went wrong', { type: 'error' });
    }
  }, [
    coachesList,
    currentIndex,
    isOnboarding,
    isSwitchCoachError,
    isSwitchCoachSuccess,
    navigation,
  ]);

  if (isCoachesListLoading) return <Loading />;

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <View style={{ padding: 16 }}>
          <View
            style={{
              flexDirection: pageWidth < webBreakpoints.sm ? 'column' : 'row',
              gap: 20,
              alignItems: 'center',
            }}
          >
            <Avatar imageSrc={{ uri: coachesList?.[currentIndex].avatarUrl }} size={200} />
            <View
              style={{
                gap: 16,
                alignItems: pageWidth < webBreakpoints.sm ? 'center' : 'flex-start',
              }}
            >
              <Text variant='h2' style={{ marginTop: 8 }}>
                {coachesList?.[currentIndex].firstName} {coachesList?.[currentIndex].lastName}
              </Text>
              <Text variant='button'>Specialities and personality</Text>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {coachesList?.[currentIndex].tags?.split(',').map((tag) => (
                  <View key={tag} style={styles.tag}>
                    <Text variant='body2' style={{ color: colors.green600 }}>
                      {tag}
                    </Text>
                  </View>
                ))}
              </View>
              <Text variant='button'>
                Timezone - {coachesList?.[currentIndex].timezone || 'ET'}
              </Text>
            </View>
          </View>
          <Markdown className='react-markdown'>
            {coachesList?.[currentIndex].richTextDescription}
          </Markdown>
          <Button
            title='Choose'
            isLoading={isSwitchCoachLoading}
            style={{ marginTop: 50 }}
            onPress={() => switchCoach(coachesList?.[currentIndex].userId ?? '')}
          />
        </View>
      </Modal>

      <View style={styles.container}>
        <Text variant='h3' style={{ textAlign: 'center' }}>
          Your recommended coaches
        </Text>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          {coachesList?.map((coach, index) => (
            <Card key={index} style={styles.cardStyle}>
              <View style={{ flex: 1, justifyContent: 'space-between' }}>
                <View style={{ alignItems: 'center', gap: 16 }}>
                  <Avatar imageSrc={{ uri: coach.avatarUrl }} size={120} />
                  <Text variant='h2' style={{ marginTop: 8 }}>
                    {coach.firstName} {coach.lastName}
                  </Text>
                  <Text variant='body1' style={{ textAlign: 'center' }} numberOfLines={3}>
                    {coach.oneLiner}
                  </Text>
                  <Text variant='button'>Specialities and personality</Text>
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {coach.tags?.split(',').map((tag) => (
                      <View key={tag} style={styles.tag}>
                        <Text variant='body2' style={{ color: colors.green600 }}>
                          {tag}
                        </Text>
                      </View>
                    ))}
                  </View>
                  <Text variant='button'>Timezone - {coach.timezone || 'ET'}</Text>
                </View>
                <View>
                  <Button
                    variant='text'
                    title='Learn more'
                    onPress={() => {
                      setCurrentIndex(index);
                      setIsModalOpen(true);
                    }}
                  />
                  <Button
                    title='Choose'
                    isDisabled={isSwitchCoachLoading}
                    style={{ alignSelf: 'center', marginBottom: 16 }}
                    onPress={() => {
                      setCurrentIndex(index);
                      switchCoach(coach.userId ?? '');
                    }}
                  />
                </View>
              </View>
            </Card>
          ))}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
    paddingHorizontal: 16,
    backgroundColor: colors.beige100,
  },
  tag: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.green500,
    backgroundColor: colors.green100,
    borderRadius: 50,
    paddingHorizontal: 16,
    marginRight: 16,
    marginBottom: 8,
  },
  cardStyle: {
    padding: 16,
    paddingTop: 40,
    margin: 24,
    width: 350,
  },
});
