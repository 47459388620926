import { useMutation } from '@tanstack/react-query';
import { logError } from '../utils/common';
import { api } from './config';

const applyCompanyCode = async (companyCode: string) => {
  const url = 'checkout/apply-company-code';

  try {
    await api.put(url, { json: { companyCode } });
  } catch (error) {
    logError(`PUT -> ${url}`, error);
    throw error;
  }
};

export const useApplyCompanyCodeMutation = () => {
  return useMutation((companyCode: string) => applyCompanyCode(companyCode));
};
