import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
/* eslint-disable-next-line */
import { Buffer } from 'buffer';
import React, { useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import { USER } from '../src/global/constants';
import { AUTH0_AUDIENCE, AUTH0_DOMAIN, WEB_AUTH0_CLIENT_ID } from '../src/global/envs';
import { storage } from '../src/utils/localStorage';
import { AppNavigation } from './navigation/AppNavigation';
window.Buffer = Buffer;

if (!__DEV__) {
  const user = storage.getString(USER);
  Sentry.init({
    dsn: 'https://316918d5f9608c5cb0518a3144d69b05@o4504332491161600.ingest.us.sentry.io/4506983335002112',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  Sentry.setUser({ user });
}

export const App = () => {
  const queryClientRef = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 20,
        },
      },
    }),
  );

  return (
    <>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={WEB_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: AUTH0_AUDIENCE,
          connection: 'email',
        }}
      >
        <QueryClientProvider client={queryClientRef.current}>
          <AppNavigation />
        </QueryClientProvider>
      </Auth0Provider>
      <ToastContainer position='top-center' autoClose={3000} closeOnClick theme='light' />
    </>
  );
};
