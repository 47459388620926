import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import type { UpcomingEvent } from '../global/types';
import type { AuthenticatedStackParams } from '../navigation/types';
import { colors } from '../theme/constants';
import { formatUpcomingEventDate, generateRandomGoingCount } from '../utils/common';
import { Avatar } from './Avatar';
import { Button } from './Button';
import { Card } from './Card';
import { Text } from './Text';

type Props = {
  event: UpcomingEvent;
  onPress?: () => void;
};

export const Event: React.FC<Props> = ({ event, onPress }) => {
  const navigation = useNavigation<StackNavigationProp<AuthenticatedStackParams>>();
  const randomGoingCount = generateRandomGoingCount(Number(event.rsvp_attn_going));
  const dateTime = formatUpcomingEventDate(event);

  return (
    <TouchableOpacity
      testID='event'
      onPress={() =>
        onPress
          ? onPress()
          : navigation.navigate('EventDetails', {
              event: {
                ...event,
                rsvp_attn_going: randomGoingCount.toString(),
                date_start: dateTime,
              },
            })
      }
    >
      <Card style={{ width: 300, height: 220 }}>
        <View style={styles.itemWrapper}>
          <View>
            <Text variant='body2' style={{ color: colors.grey600 }}>
              {dateTime}
            </Text>
            <Text variant='button' style={{ marginTop: 8 }} numberOfLines={1}>
              {event.title}
            </Text>
            <View style={styles.avatarLine}>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                <Avatar
                  imageSrc={{ uri: event.avatarUrl }}
                  fallbackText={event.organizer[0]}
                  size={32}
                />
                <Text variant='body2'>
                  With <Text style={{ fontWeight: 'bold' }}>{event.organizer}</Text>
                </Text>
              </View>
              <Text variant='body3' style={{ color: colors.grey600 }}>
                {randomGoingCount} people going
              </Text>
            </View>
          </View>
          <Button
            title='Add to your calendar'
            variant='outlined'
            style={{ alignSelf: 'center' }}
            onPress={() =>
              onPress
                ? onPress()
                : navigation.navigate('EventDetailsWebView', { url: event.link_long })
            }
          />
        </View>
      </Card>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemWrapper: {
    marginHorizontal: 24,
    marginVertical: 16,
    flex: 1,
    justifyContent: 'space-between',
  },
  avatarLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginVertical: 16,
  },
});
