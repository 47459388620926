import { useFocusEffect } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { Alert, Linking, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useGetMeetingStatusesQuery } from '../../src/api/useGetMeetingStatusesQuery';
import { useGetUserQuery } from '../../src/api/useGetUserQuery';
import { Text } from '../../src/components/Text';
import { VideoIcon } from '../../src/components/icons';
import { MeetingCta } from '../../src/global/types';
import { colors } from '../../src/theme/constants';
import { getFullCalendarUrl } from '../../src/utils/common';

export const CallInfo = () => {
  const { data: userData } = useGetUserQuery();
  const { data: meetingStatuses, refetch: refetchMeetingStatuses } =
    useGetMeetingStatusesQuery('me');
  const meetingStatus = meetingStatuses?.find((s) => s.type === 'regular');
  const isActiveCall = meetingStatus?.cta === MeetingCta.ACTIVE;
  const isScheduledCall = meetingStatus?.cta === MeetingCta.SCHEDULED;

  const fullCalendarUrl = useMemo(
    () =>
      getFullCalendarUrl({
        baseUrl: userData?.coach?.calendarUrl,
        user: userData?.me,
      }),
    [userData],
  );

  useFocusEffect(
    useCallback(() => {
      refetchMeetingStatuses?.();
    }, [refetchMeetingStatuses]),
  );

  const handleBtnPress = () => {
    if (isActiveCall) {
      if (!meetingStatus?.event?.callUrl) {
        Alert.alert('No call URL specified');
        return;
      }
      Linking.openURL(meetingStatus?.event?.callUrl);
    } else {
      Linking.openURL(fullCalendarUrl);
    }
  };

  return (
    <>
      <TouchableOpacity onPress={handleBtnPress}>
        <View
          style={[
            styles.container,
            {
              backgroundColor: isActiveCall || isScheduledCall ? colors.green100 : colors.orange300,
            },
          ]}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <VideoIcon />
            <Text style={{ fontSize: 12 }}>
              {isActiveCall
                ? 'Join your call'
                : isScheduledCall
                  ? dayjs(meetingStatus?.event?.startTime).format('ddd, MMM DD, h:mmA')
                  : 'Schedule your next call'}
            </Text>
          </View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '600',
              color: isActiveCall || isScheduledCall ? colors.green600 : colors.orange600,
            }}
          >
            {isActiveCall ? 'Join' : isScheduledCall ? 'Reschedule call' : 'Schedule call'}
          </Text>
        </View>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    padding: 10,
    width: 244,
  },
});
