import { useQuery } from '@tanstack/react-query';
import type { UpcomingEvent } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';
import { EVENTS_LIST } from './queryKeys';

const fetchEventsList = async () => {
  const url = 'communityEvents/upcoming';
  try {
    const data = await api.get(url).json<{
      upcomingEvents: UpcomingEvent[];
      hosts: {
        email: string;
        avatarUrl: string;
      }[];
    }>();
    const events = data.upcomingEvents.map((event) => {
      const avatarUrl =
        data.hosts.find((host) => host.email === event.organizer_email)?.avatarUrl || '';
      return { ...event, avatarUrl };
    });

    return events;
  } catch (error) {
    logError(`GET -> ${url}`, error);
    return null;
  }
};

export const useGetEventsListQuery = () => useQuery([EVENTS_LIST], fetchEventsList);
