import React from 'react';
import ReactModal from 'react-modal';
import { TouchableOpacity } from 'react-native';
import { BlackCloseIcon } from '../../src/components/icons';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  contentStyle?: object;
};

export const Modal: React.FC<Props> = ({ children, onClose, isOpen, contentStyle }) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: { borderRadius: 16, margin: 'auto', ...contentStyle },
        overlay: { backgroundColor: 'rgba(0,0,0, 0.5)' },
      }}
    >
      <>
        {children}
        <TouchableOpacity onPress={onClose} style={{ position: 'absolute', right: 20, top: 20 }}>
          <BlackCloseIcon />
        </TouchableOpacity>
      </>
    </ReactModal>
  );
};
