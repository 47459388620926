import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Platform } from 'react-native';
import { Chat as StreamChat } from 'stream-chat-react';
import { trackActivity } from '../../src/api/trackActivity';
import { useGetUserQuery } from '../../src/api/useGetUserQuery';
import { X_IMPERSONATE } from '../../src/global/constants';
import { ActivityType, SubscriptionStatus } from '../../src/global/types';
import { checkIsUserPaid } from '../../src/hooks/usePurchases';
import { useChatStore } from '../../src/store/useChatStore';
import { getChatClient } from '../../src/utils/common';
import { storage } from '../../src/utils/localStorage';
import { DownloadApp } from '../components/DownloadApp';
import { Loading } from '../components/Loading';
import { useChatClient } from '../hooks/useChatClient';
import { OnboardingQuestion } from '../pages/OnboardingQuestion';
import { OnboardingUserDetails } from '../pages/OnboardingUserDetails';
import { Payment } from '../pages/Payment';
import { SwitchCoach } from '../pages/SwitchCoach';
import { DrawerStack } from './DrawerStack';
import type { AuthenticatedStackParams } from './types';

const Stack = createStackNavigator<AuthenticatedStackParams>();
const chatClient = getChatClient();

export const AuthenticatedStack = () => {
  const [userRefetchInterval, setUserRefetchInterval] = useState<number | undefined>(3000);
  const { data: userData, isLoading: isUserDataLoading } = useGetUserQuery(userRefetchInterval);
  const { clientIsReady } = useChatClient(userData?.me);
  const { getRemoteChannel } = useChatStore();
  const [hasVisited, setHasVisited] = useState(false);

  useEffect(() => {
    const impersonateId = storage.getString(X_IMPERSONATE);
    if (impersonateId) return; // do not send activity for impersonated user
    (async () => {
      trackActivity(ActivityType.AppOpen, {
        platform: Platform.OS,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    })();
  }, []);

  useEffect(() => {
    if (!userData) return;
    setUserRefetchInterval(undefined);
    checkIsUserPaid(userData.me);

    const visited = storage.getBoolean(`${userData.me.userId}-visited`) ?? false;
    setHasVisited(visited);

    addEventListener('beforeunload', () => {
      storage.set(`${userData.me.userId}-visited`, true);
    });
  }, [userData]);

  useEffect(() => {
    if (userData?.me.channelId && clientIsReady) getRemoteChannel(userData?.me.channelId);
  }, [clientIsReady, getRemoteChannel, userData?.me.channelId]);

  if (isMobile && hasVisited) return <DownloadApp />;

  if (!clientIsReady || isUserDataLoading) return <Loading />;

  const initialPageName =
    userData?.me.subscriptionStatus === SubscriptionStatus.FREE
      ? 'OnboardingUserDetails'
      : 'DrawerStack';

  return (
    <StreamChat client={chatClient}>
      <Stack.Navigator initialRouteName={initialPageName} screenOptions={{ headerShown: false }}>
        <Stack.Screen name='OnboardingUserDetails' component={OnboardingUserDetails} />
        <Stack.Screen name='OnboardingQuestion' component={OnboardingQuestion} />
        <Stack.Screen name='SwitchCoach' component={SwitchCoach} />
        <Stack.Screen name='Payment' component={Payment} />
        <Stack.Screen name='DrawerStack' component={DrawerStack} />
      </Stack.Navigator>
    </StreamChat>
  );
};
