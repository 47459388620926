import { useCallback, useEffect, useState } from 'react';
import { getChatToken } from '../../src/api/getChatToken';
import { CHAT_TOKEN } from '../../src/global/constants';
import type { Nullish, User } from '../../src/global/types';
import { useUserStore } from '../../src/store/useUserStore';
import { getChatClient, logError } from '../../src/utils/common';
import { storage } from '../../src/utils/localStorage';

const chatClient = getChatClient();

export const useChatClient = (user: Nullish<User>) => {
  const [clientIsReady, setClientIsReady] = useState(false);
  const setUnreadMsgCount = useUserStore((state) => state.setUnreadMsgCount);

  const setupClient = useCallback(async () => {
    try {
      const chatToken = await getChatToken();
      storage.set(CHAT_TOKEN, chatToken ?? '');
      const chatUser = await chatClient.connectUser(
        {
          id: user?.userId as string,
          name: user?.firstName,
        },
        chatToken,
      );
      // @ts-ignore-next-line
      setUnreadMsgCount(chatUser.me.unread_count);
      setClientIsReady(true);
    } catch (error) {
      logError('useChatClient -> setupClient', error);
    }
  }, [setUnreadMsgCount, user?.firstName, user?.userId]);

  useEffect(() => {
    if (!chatClient.userID && user) {
      setupClient();
    }
  }, [setupClient, user]);

  return { clientIsReady };
};
