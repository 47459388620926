import type { StackScreenProps } from '@react-navigation/stack';
import React, { useLayoutEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { StyleSheet, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { Channel, MessageInput, VirtualizedMessageList, Window } from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';
import { useGetUserQuery } from '../../../src/api/useGetUserQuery';
import { Avatar } from '../../../src/components/Avatar';
import { Button } from '../../../src/components/Button';
import { Card } from '../../../src/components/Card';
import { Text } from '../../../src/components/Text';
import { useChatStore } from '../../../src/store/useChatStore';
import { useUserStore } from '../../../src/store/useUserStore';
import { colors, webBreakpoints } from '../../../src/theme/constants';
import { CoachDetails } from '../../components/CoachDetails';
import { Loading } from '../../components/Loading';
import useNewMessageNotification from '../../hooks/useNewMessageNotification';
import type { DrawerParams } from '../../navigation/types';
import { CustomMessage } from './components/CustomMessage';

type Props = StackScreenProps<DrawerParams, 'Chat'>;

export const Chat: React.FC<Props> = () => {
  const { width: pageWidth } = useWindowDimensions();
  const isPaidUser = useUserStore((state) => state.isPaidUser);
  const { channel } = useChatStore();
  const { data: userData } = useGetUserQuery();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [areCoachDetailsVisible, setAreCoachDetailsVisible] = useState(false);
  const isSmallWidth = pageWidth < webBreakpoints.md;
  const { showNotificationBanner, grantPermission } = useNewMessageNotification();

  useLayoutEffect(() => {
    setIsDrawerOpen(!isSmallWidth || areCoachDetailsVisible);
  }, [areCoachDetailsVisible, isSmallWidth]);

  if (!channel) return <Loading />;

  return (
    <>
      {showNotificationBanner && (
        <Card style={styles.notificationBannerContainer}>
          <>
            <Text variant='h3'>Hi there!</Text>
            <Text variant='body1'>We need your permission to enable desktop notifications</Text>
            <Button title='OK' onPress={grantPermission} />
          </>
        </Card>
      )}
      {isSmallWidth && (
        <TouchableOpacity
          onPress={() => setAreCoachDetailsVisible((prev) => !prev)}
          style={{ position: 'absolute', right: 10, top: 10, zIndex: 100 }}
        >
          <Avatar imageSrc={{ uri: userData?.coach?.avatarUrl }} size={45} />
        </TouchableOpacity>
      )}
      <Channel
        channel={channel}
        MessageDeleted={() => null}
        MessageStatus={() => null}
        EmojiPicker={EmojiPicker}
        VirtualMessage={CustomMessage}
      >
        <View style={{ flex: 1, marginRight: isSmallWidth ? 0 : 400 }}>
          <Window>
            <VirtualizedMessageList
              messageActions={['copy', 'quote', 'delete', 'retry', 'react']}
            />
            {isPaidUser && <MessageInput grow />}
          </Window>
        </View>
        <Drawer
          open={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setAreCoachDetailsVisible(false);
          }}
          direction='right'
          enableOverlay={isSmallWidth}
          style={{
            padding: 20,
            paddingTop: 100,
            position: 'fixed',
            width: 400,
            overflowY: 'scroll',
          }}
        >
          <CoachDetails />
        </Drawer>
      </Channel>
    </>
  );
};

const styles = StyleSheet.create({
  notificationBannerContainer: {
    position: 'absolute',
    bottom: 32,
    right: 32,
    zIndex: 1000,
    backgroundColor: colors.orange300,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    gap: 16,
  },
});
