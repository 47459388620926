import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Text } from '../../src/components/Text';
import { APP_STORE_URL, PLAY_STORE_URL } from '../../src/global/constants';
import { colors } from '../../src/theme/constants';

export const DownloadApp = () => {
  return (
    <View id='downloadApp' style={styles.container}>
      <Text variant='h3' style={{ color: colors.white100 }}>
        Agave Health
      </Text>
      <Image
        source={require('../../src/assets/images/logo-md.png')}
        style={{ height: 120, width: 120, borderRadius: 25 }}
      />
      <Text variant='body1' style={{ color: colors.white100 }}>
        Are you on your phone?
      </Text>
      <Text variant='body1' style={{ color: colors.white100, textAlign: 'center' }}>
        Open or download the Agave app to continue your journey.
      </Text>
      <a href={APP_STORE_URL} target='_blank' rel='noopener norefferer'>
        <Image
          source={require('../../src/assets/images/app-store.png')}
          style={{ width: 200, height: 70 }}
        />
      </a>
      <a href={PLAY_STORE_URL} target='_blank' rel='noopener norefferer'>
        <Image
          source={require('../../src/assets/images/play-store.png')}
          style={{ width: 200, height: 70 }}
        />
      </a>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    padding: 16,
  },
});
