import React, { useState } from 'react';
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  type PressableProps,
  type StyleProp,
  type TextStyle,
  type ViewStyle,
} from 'react-native';
import { colors } from '../theme/constants';
import { Text } from './Text';

type Props = {
  title: string;
  style?: StyleProp<ViewStyle>;
  pressedStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  pressedTextStyle?: StyleProp<TextStyle>;
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
} & PressableProps;

export const Button: React.FC<Props> = ({
  title,
  variant = 'contained',
  style,
  pressedStyle,
  textStyle,
  pressedTextStyle,
  isDisabled,
  isLoading,
  startIcon,
  endIcon,
  ...restOfPRops
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const variantStyles = {
    contained: {
      backgroundColor: isDisabled ? colors.grey600 : colors.orange600,
      borderColor: 'transparent',
    },
    outlined: {
      backgroundColor: 'transparent',
      borderColor: colors.orange600,
    },
    text: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      height: 40,
      width: 'auto',
    },
  };
  const variantColor =
    variant === 'outlined' || variant === 'text' ? colors.orange600 : colors.white100;

  return (
    <Pressable
      style={({ pressed }) => {
        return [
          styles.container,
          variantStyles[variant],
          { opacity: pressed || isLoading ? 0.5 : isHovered ? 0.8 : 1 },
          style,
          ...[pressed ? [pressedStyle] : []],
        ];
      }}
      android_ripple={{ color: 'transparent' }}
      disabled={isDisabled || isLoading}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
      {...restOfPRops}
    >
      {startIcon && !isLoading ? startIcon : null}
      {isLoading ? (
        <ActivityIndicator color={variantColor} />
      ) : (
        <Text
          variant='button'
          style={[{ color: variantColor }, textStyle, ...[isPressed ? [pressedTextStyle] : []]]}
        >
          {title}
        </Text>
      )}
      {endIcon && !isLoading ? endIcon : null}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 240,
    height: 45,
    padding: 8,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
});
