import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '../../../components/Text';
import { colors } from '../../../theme/constants';

export const TasksDoneView = () => {
  return (
    <View style={styles.container}>
      <Text variant='button' style={{ color: colors.green500, alignSelf: 'center' }}>
        All done!
      </Text>
      <Text variant='body1' style={{ textAlign: 'center' }}>
        You've completed all your tasks. {'\n'} Check back in tomorrow to continue your journey.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white100,
    borderRadius: 8,
    paddingVertical: 16,
    borderWidth: 1,
    borderBlockColor: colors.green500,
  },
});
