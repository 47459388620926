import type { StackScreenProps } from '@react-navigation/stack';
import React, { useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Button } from '../../src/components/Button';
import { Input } from '../../src/components/Input';
import { OptionItem } from '../../src/components/OptionItem';
import { ProgressIndicator } from '../../src/components/ProgressIndicator';
import { Text } from '../../src/components/Text';
import { useOnboardingStore } from '../../src/store/useOnboardingStore';
import { colors } from '../../src/theme/constants';
import type { AuthenticatedStackParams } from '../navigation/types';

type Props = StackScreenProps<AuthenticatedStackParams, 'OnboardingQuestion'>;

export const OnboardingQuestion: React.FC<Props> = ({ navigation }) => {
  const updateOnboardingAnswers = useOnboardingStore((state) => state.updateOnboardingAnswers);
  const [selectedValues, setSelectedValues] = useState<{ key: string; value: string }[]>([]);
  const [otherAnswer, setOtherAnswer] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleItemPress = (option: { key: string; value: string }) => {
    if (selectedValues.some((v) => v.key === option.key)) {
      setSelectedValues(selectedValues.filter((o) => o.key !== option.key));
    } else {
      setSelectedValues([...selectedValues, option]);
    }
  };

  const handleNextPress = async () => {
    setIsSubmitting(true);
    await updateOnboardingAnswers('your-challenge-is', {
      questionSlug: 'your-challenge-is',
      answers: [...selectedValues, { key: 'other', value: otherAnswer }],
    });
    navigation.navigate('SwitchCoach', { activityName: 'onboarding-choose-coach' });
    setIsSubmitting(false);
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <View style={styles.headerContainer}>
          <Image
            source={require('../../src/assets/images/logo.png')}
            style={{ width: 24, height: 24 }}
          />
          <Text variant='body2'>Tell us a bit about yourself</Text>
        </View>
        <ProgressIndicator count={2} current={2} />
        <Text variant='h3' style={{ textAlign: 'center', marginVertical: 32 }}>
          What are your biggest challenges today?
        </Text>
        <View style={{ gap: 16 }}>
          {[
            { value: 'Time management and planning', key: 'tm-planning' },
            { value: 'Focus and attention', key: 'focus-attention' },
            { value: 'Work life balance', key: 'work-life-balance' },
            { value: 'Emotional regulation', key: 'emotional-reg' },
            { value: 'Interpersonal relationships', key: 'intre-relationships' },
            { value: 'Impulsivity', key: 'impulsivity' },
            { value: 'Overall health and wellbeing', key: 'health-wellbeing' },
            { value: 'Other', key: 'other' },
          ].map((option, index) => (
            <OptionItem
              key={index}
              text={option.value}
              isSelected={selectedValues.some((v) => v.key === option.key)}
              onSelect={() => handleItemPress(option)}
            />
          ))}
          {selectedValues.some((v) => v.key === 'other') && (
            <Input
              value={otherAnswer}
              onChangeText={setOtherAnswer}
              placeholder='Please specify...'
              multiline
              style={{ height: 'auto', marginVertical: 8 }}
            />
          )}
        </View>
        <Button
          title='Next'
          isLoading={isSubmitting}
          isDisabled={
            !selectedValues.length ||
            (selectedValues.some((v) => v.key === 'other') && !otherAnswer)
          }
          style={{ alignSelf: 'center', marginTop: 40 }}
          onPress={handleNextPress}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 50,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.beige100,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    gap: 8,
  },
});
