import { logError } from '../utils/common';
import { api } from './config';

export const getChatToken = async () => {
  const url = 'chat/authorize';
  try {
    const { streamToken } = await api.post(url).json<{ streamToken: string }>();
    return streamToken;
  } catch (error) {
    logError(`POST -> ${url}`, error);
  }
};
