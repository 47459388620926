import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { useGetPricesQuery } from '../api/useGetPricesQuery';
import { useGetUserQuery } from '../api/useGetUserQuery';
import { SubscriptionStatus } from '../global/types';
import { useManageMembership } from '../hooks/useManageMembership';
import type { AuthenticatedStackParams } from '../navigation/types';
import { colors } from '../theme/constants';
import { isWeb } from '../utils/platform';
import { Button } from './Button';
import { Text } from './Text';
import { BigWalletIcon, TimerIconFilled } from './icons';

export const UpgradeBanner = () => {
  const navigation = useNavigation<StackNavigationProp<AuthenticatedStackParams>>();
  const { stripePortalUrl } = useManageMembership();
  const { data: userData } = useGetUserQuery();
  const { data: appPrice } = useGetPricesQuery();
  const { accessDaysLeft = 0, subscriptionStatus, isBillingIssue } = userData?.me || {};
  const hasTrialEnded = accessDaysLeft <= 0;

  const Wrapper = ({ children }: { children: React.ReactElement }) => (
    <View
      style={[
        styles.wrapper,
        {
          backgroundColor: hasTrialEnded ? colors.green600 : colors.orange600,
        },
      ]}
    >
      {children}
    </View>
  );

  const ActionButton = ({ btnLabel, portalUrl }: { btnLabel: string; portalUrl?: string }) => (
    <>
      <Button
        title={btnLabel}
        style={styles.button}
        textStyle={{
          color: hasTrialEnded ? colors.green600 : colors.orange600,
          fontSize: 12,
        }}
        onPress={() =>
          portalUrl && isWeb
            ? Linking.openURL(portalUrl)
            : portalUrl
              ? navigation.navigate('PaymentWebView', { url: portalUrl })
              : navigation.navigate('Payment')
        }
      />
    </>
  );

  const WithDaysLeft = ({ text }: { text: string }) => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <TimerIconFilled />
      <Text variant='body1' style={{ color: colors.white100, marginLeft: 8 }}>
        {text}
      </Text>
      <Text variant='button' style={{ color: colors.white100 }}>
        {` ${accessDaysLeft}`} {`day${accessDaysLeft === 1 ? '' : 's'}`}
      </Text>
    </View>
  );

  const WithNoDaysLeft = ({ text }: { text: string }) => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <BigWalletIcon />
      <Text variant='body2' style={{ color: colors.white100, marginLeft: 8 }}>
        {text}
      </Text>
    </View>
  );

  if (subscriptionStatus === SubscriptionStatus.TRIAL && accessDaysLeft > 0)
    return (
      <Wrapper>
        <>
          <WithDaysLeft text='Your free trial ends in' />
          <ActionButton btnLabel='Upgrade' />
        </>
      </Wrapper>
    );

  if (subscriptionStatus === SubscriptionStatus.TRIAL && accessDaysLeft <= 0)
    return (
      <Wrapper>
        <>
          <WithNoDaysLeft
            text={`Your trial has ended. Upgrade for only ${'\n'} $${appPrice?.DISCOUNTED_PRICE || 0} to keep full access to your care.`}
          />
          <ActionButton btnLabel='Upgrade' />
        </>
      </Wrapper>
    );

  if (subscriptionStatus === SubscriptionStatus.CANCELED && isBillingIssue)
    return (
      <Wrapper>
        <>
          <WithNoDaysLeft text='Credit card billing issue.' />
          <ActionButton btnLabel='Fix' portalUrl={stripePortalUrl} />
        </>
      </Wrapper>
    );

  if (subscriptionStatus === SubscriptionStatus.CANCELED && !isBillingIssue && accessDaysLeft > 0)
    return (
      <Wrapper>
        <>
          <WithDaysLeft text='Your access will end in' />
          <ActionButton btnLabel='Renew' />
        </>
      </Wrapper>
    );

  if (subscriptionStatus === SubscriptionStatus.CANCELED && !isBillingIssue && accessDaysLeft <= 0)
    return (
      <Wrapper>
        <>
          <WithNoDaysLeft text='Your access has ended.' />
          <ActionButton btnLabel='Renew' />
        </>
      </Wrapper>
    );

  return null;
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.white100,
    width: 70,
    height: 27,
    borderRadius: 5,
    padding: 0,
  },
  wrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    paddingHorizontal: 16,
    zIndex: 100,
  },
});
