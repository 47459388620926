import type { Program, ProgramAnswers } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';

export const fetchProgramById = async (programId: string) => {
  const url = `activity/v2/cbt/${programId}/`;

  try {
    const programData = await api.get(url).json<{ program: Program; answers: ProgramAnswers }>();
    return programData;
  } catch (error) {
    logError(`GET -> ${url}`, error);
  }
};
