import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useGetUserQuery } from '../../src/api/useGetUserQuery';
import { storage } from '../../src/utils/localStorage';
import { WithDrawer } from '../components/WithDrawer';
import { Chat } from '../pages/Chat';
import { Home } from '../pages/Home';
import { Settings } from '../pages/Settings';
import type { DrawerParams } from './types';

const Stack = createStackNavigator<DrawerParams>();

export const DrawerStack = () => {
  const { data: userData } = useGetUserQuery();
  const initialPageName = storage.getBoolean(`${userData?.me.userId}-visited`) ? 'Home' : 'Chat';

  return (
    <WithDrawer subscriptionStatus={userData?.me.subscriptionStatus}>
      <Stack.Navigator initialRouteName={initialPageName} screenOptions={{ headerShown: false }}>
        <Stack.Screen name='Home' component={Home} />
        <Stack.Screen name='Chat' component={Chat} />
        <Stack.Screen name='Settings' component={Settings} />
      </Stack.Navigator>
    </WithDrawer>
  );
};
