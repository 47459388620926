import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { SignIn } from '../pages/SignIn';
import type { UnAuthenticatedStackParams } from './types';

const Stack = createStackNavigator<UnAuthenticatedStackParams>();

export const UnAuthenticatedStack = () => {
  return (
    <Stack.Navigator initialRouteName='SignIn' screenOptions={{ headerShown: false }}>
      <Stack.Screen name='SignIn' component={SignIn} />
    </Stack.Navigator>
  );
};
