import React from 'react';
import { View } from 'react-native';

type Props = {
  value: number;
  size: number;
  backgroundColor: string;
  trackColor: string;
};

export const Progress: React.FC<Props> = ({ value, size, backgroundColor, trackColor }) => {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor,
        width: '100%',
        borderRadius: size,
        maxHeight: size,
        height: size,
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: trackColor,
          width: `${value}%`,
          borderRadius: size,
          maxHeight: size,
          height: size,
        }}
      />
    </View>
  );
};
