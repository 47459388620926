import React from 'react';
import { MessageSimple, useMessageContext } from 'stream-chat-react';
import { ChatMessageCustomType } from '../../../../src/global/types';
import { BotMessage } from './BotMessage';
import { CbtLessonAnswersMessage } from './CbtLessonAnswersMessage';
import { DailyCheckInAnswersMessage } from './DailyCheckInAnswersMessage';

export const CustomMessage = () => {
  const { message } = useMessageContext();

  switch (message.attachments?.[0]?.type) {
    case ChatMessageCustomType.Bot:
      return <BotMessage />;
    case ChatMessageCustomType.CbtLessonAnswers:
      return <CbtLessonAnswersMessage />;
    case ChatMessageCustomType.DailyCheckInAnswers:
      return <DailyCheckInAnswersMessage />;
    default:
      return <MessageSimple />;
  }
};
