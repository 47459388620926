export type Nullish<T> = T | null | undefined;

export enum Environment {
  Prod = 'prod',
  Stg = 'stg',
}

export enum UserGender {
  Female = 'Female',
  Male = 'Male',
  NonBinary = 'Non-Binary',
  PreferNotToDisclose = 'Prefer not to disclose',
}

export enum Feeling {
  CompletelyDissatisfied = 'Completely dissatisfied',
  Dissatisfied = 'Dissatisfied',
  Neutral = 'Neutral',
  Satisfied = 'Satisfied',
  CompletelySatisfied = 'Completely satisfied',
}

export type NotificationData = {
  screenName?: 'HOME' | 'CHAT' | 'EXPLORE' | 'PAYWALL' | 'LINK';
  notificationFeature?: string;
  notificationId?: number;
  channel_id?: string;
  link?: string;
};

export type AppPrice = { DISCOUNTED_PRICE: string; FULL_PRICE: string };

export type UpcomingEvent = {
  id: string;
  title: string;
  description: string;
  organizer: string;
  organizer_email: string;
  date_start: string;
  date_start_time: string;
  timezone: string;
  rsvp_attn_going: string;
  link_long: string;
  avatarUrl: string;
};

export enum ActivityType {
  AppOpen = 'app-open',
  Notifications = 'notifications',
  DailyCheckIn = 'daily-check-in',
  LessonDone = 'lesson-done',
  LessonStarted = 'lesson-started',
  VideoSkipped = 'video-skipped',
  VideoScriptOpened = 'video-script-opened',
  ReferralClicked = 'referral-clicked',
  Engagement = 'engagement',
}

export type DailyCheckIn = {
  feeling: 'Awful' | 'Bad' | 'Meh' | 'Good' | 'Awesome' | '';
  reasons: string[];
  domains: string[];
};

export enum SubscriptionStatus {
  FREE = 'free',
  PREMIUM = 'premium',
  CANCELED = 'canceled',
  TRIAL = 'trial',
  PAUSED = 'paused',
}

export type OnboardingAnswer = {
  questionSlug: string;
  answers: {
    key?: string;
    value?: string | number | boolean | Date;
    text?: string;
  }[];
};

export type User = {
  userId: string;
  nickname: string;
  email: string;
  avatarUrl: string;
  isCoach: boolean;
  isAdmin: boolean;
  isAlpha: boolean;
  subscriptionStatus: SubscriptionStatus;
  dateOfBirth: string;
  createdAt: string;
  channelId?: string;
  coachId?: string;
  firstName?: string;
  lastName?: string;
  gender?: UserGender;
  subscriptionLastPaymentDate?: string;
  accessDaysLeft?: number;
  isBillingIssue?: boolean;
  clientCompany: string;
  address?: UserAddress;
  primaryProvider: IdentityProvider;
  isOnboardingCompleted: boolean;
};

export type Coach = {
  userId: string;
  nickname: string;
  email: string;
  avatarUrl: string;
  calendarUrl: string;
  introCallUrl: string;
  description: string;
  firstName?: string;
  lastName?: string;
  richTextDescription?: string;
  oneLiner?: string;
  tags?: string;
  timezone?: string;
  license?: string;
};

export type UserAddress = {
  zipCode: string;
  street: string;
  city: string;
  state: string;
};

export type News = {
  _id: string;
  title: string;
  shortDescription: string;
  icon: string;
  link: {
    inAppLink: 'HOME' | 'CHAT' | 'EXPLORE' | 'PAYWALL' | 'LINK' | 'DETAILS';
    url: string;
    description: string;
  };
  createdBy: string;
  isDeleted: boolean;
  updatedAt: string;
  isRead: boolean;
};

export enum MeetingCta {
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
  NONE = 'none',
  SCHEDULE_NOW = 'schedule_now',
}

export enum DailyTaskType {
  IntakeForm = 'intake-form',
  HowYouFeel = 'how-you-feel',
  IntroToAdhd = 'intro-to-adhd',
  DailyProgram = 'daily-program',
}

export type DailyTask = {
  task: DailyTaskType;
  title: string;
  priority: number;
  isComplete: boolean;
  programUid?: string;
  formUid?: string;
  lessonsDone?: number;
  lessonsCount?: number;
  isRecommended?: boolean;
};

export type DailyStatus = {
  currentProgramUid: string;
  isCheckInDone: boolean;
  isDailyProgramDone: boolean;
  tasks: DailyTask[];
};

export type StatsOverview = {
  donePrograms: number;
  doneLessons: number;
  checkIns: number;
};

export type Step = {
  slug: string;
  videoURL?: string;
  videoScript?: string;
  imageURL?: string;
  animationURL?: string;
  nextStep: string | null;
  nextScreenName: string | null;
  screenName: string;
  prevStep?: string;
  prompts?: Prompt[];
  answers?: string[];
  answersStepRef?: string;
  inputsCount?: number;
  audio?: { url?: string; title?: string; imageURL?: string; duration?: string };
  timer?: { duration?: number; isBreathing?: boolean };
  weightedOptions: { title: string; weight: number }[];
  question?: {
    options?: string[];
    answer?: string[];
    maxAnswersCount?: number;
  };
  infographics?: {
    imageUrl?: string;
    items?: {
      title?: string;
      description?: string[];
      imageUrl?: string;
    }[];
  };
  surveyResults?: {
    title: string;
    minLabel: string;
    maxLabel: string;
    category: string;
  }[];
  category?: string;
  hint?: string;
  caption?: string;
  mainTitle?: string;
  title?: string;
  subTitle?: string;
  description?: string[];
  mainDescription?: string[];
  correctModal?: { title?: string; description?: string };
  incorrectModal?: { title?: string; description?: string };
};

export type Prompt = {
  text: string;
  label: string;
  branchTo?: string;
};

export enum LessonStatus {
  active = 'active',
  done = 'done',
  locked = 'locked',
}

export type Lesson = {
  title: string;
  steps: { [key: string]: Step };
  status: LessonStatus;
  slug: string;
  firstStep: string;
  firstScreenName: string;
  numberOfQuestions?: number;
  duration: number;
};

export type Program = {
  uid: string;
  title: string;
  subtitle: string;
  lessons: Lesson[];
  imageUrl: string;
  lessonsDone: number;
  lessonsCount: number;
  status: 'live' | 'draft';
  isSurvey: boolean;
  isRecommended: boolean;
};

export type ProgramAnswers = {
  lessons: {
    [key: string]: {
      status: LessonStatus;
      steps: {
        [key: string]: {
          answers: string[];
        };
      };
    };
  };
};

export enum ProgramScreenName {
  Video = 'Video',
  Audio = 'Audio',
  Timer = 'Timer',
  TextView = 'TextView',
  Infographics = 'Infographics',
  InfographicsWithAnswers = 'InfographicsWithAnswers',
  Feedback = 'Feedback',
  Inputs = 'Inputs',
  InputsWithLabels = 'InputsWithLabels',
  LessonDone = 'LessonDone',
  ReOrder = 'ReOrder',
  QuestionReOrder = 'QuestionReOrder',
  SingleSelect = 'SingleSelect',
  SingleSelectWithInput = 'SingleSelectWithInput',
  QuestionSingleSelect = 'QuestionSingleSelect',
  MultiSelect = 'MultiSelect',
  MultiSelectWithInput = 'MultiSelectWithInput',
  QuestionMultiSelect = 'QuestionMultiSelect',
  QuestionWithInput = 'QuestionWithInput',
  SelectsWithLabels = 'SelectsWithLabels',
  Schedule = 'Schedule',
  ChooseNew = 'ChooseNew',
  Emoji = 'Emoji',
  WeightedSingleSelect = 'WeightedSingleSelect',
  SurveyResults = 'SurveyResults',
}

export enum ChatMessageCustomType {
  Bot = 'bot',
  Audio = 'audio',
  Video = 'video',
  CbtVideo = 'cbt-video',
  CbtLessonAnswers = 'cbt-lesson-answers',
  DailyCheckInAnswers = 'daily-check-in-answers',
}

export type TherapyCourse = {
  id: string;
  title: string;
  summary: string;
  description: string;
  image: string;
  sessionsAmount: number;
  price: number;
  paymentPlan: 'PER_SESSION' | 'PER_COURSE';
  status: 'PUBLISHED' | 'DRAFT';
};

export type Therapy = {
  id: string;
  userId: string;
  courseId: string;
  therapistId: string;
  channelId: string;
  status: TherapyStatus;
  assessmentSlug: string;
  isAssessmentFilled?: boolean;
};

export enum TherapyStatus {
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
}

export type UserServiceProvider = {
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  description: string;
  avatarUrl: string;
  scheduleUrl: string;
  channelId: string | null;
  type: 'coach' | 'therapist';
};

export type MeetingStatus = {
  type: 'regular' | 'therapy';
  cta: MeetingCta;
  serviceProvider: UserServiceProvider;
  event?: {
    startTime: string;
    callUrl: string;
    reschedule_url: string;
    cancel_url: string;
  };
};

export enum IdentityProvider {
  Auth0 = 'auth0',
  Email = 'email',
  Sharecare = 'Sharecare',
}
