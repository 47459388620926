import { useQuery } from '@tanstack/react-query';
import type { Coach } from '../global/types';
import { logError } from '../utils/common';
import { api } from './config';
import { COACHES_LIST } from './queryKeys';

const fetchCoachesList = async () => {
  const url = 'chat/list_coaches';
  try {
    const { coaches } = await api.get(url).json<{ coaches: Coach[] }>();
    return coaches;
  } catch (error) {
    logError(`GET -> ${url}`, error);
    return null;
  }
};

export const useGetCoachesListQuery = () => useQuery([COACHES_LIST], fetchCoachesList);
