import { create } from 'zustand';
import { updateOnboardingData } from '../api/updateOnboardingData';
import type { OnboardingAnswer } from '../global/types';

type OnboardingStore = {
  answers: OnboardingAnswer[];
  updateOnboardingAnswers: (fromSlug: string, answer: OnboardingAnswer) => Promise<void>;
};

export const useOnboardingStore = create<OnboardingStore>()((set, get) => ({
  answers: [],
  updateOnboardingAnswers: async (fromSlug, answer) => {
    const { answers } = get();
    const updatedAnswers = [...answers, answer];
    set({ answers: updatedAnswers });
    await updateOnboardingData({ fromSlug, data: updatedAnswers });
  },
}));
