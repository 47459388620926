import { useQuery } from '@tanstack/react-query';
import { USER } from '../global/constants';
import type { Coach, User } from '../global/types';
import { logError } from '../utils/common';
import { storage } from '../utils/localStorage';
import { api } from './config';
import { CURRENT_USER } from './queryKeys';

const fetchCurrentUser = async () => {
  const url = 'user/me';
  try {
    const user = await api.get(url).json<{ me: User; coach: Coach }>();
    storage.set(
      USER,
      JSON.stringify({
        name: user?.me?.firstName,
        email: user?.me?.email,
        userId: user?.me?.userId,
      }),
    );
    return user;
  } catch (error) {
    logError(`GET -> ${url}`, error);
    return null;
  }
};

export const useGetUserQuery = (refetchInterval?: number) =>
  useQuery([CURRENT_USER], fetchCurrentUser, { refetchInterval: refetchInterval || false });
