import ky from 'ky';
import { X_IMPERSONATE } from '../global/constants';
import { API_BASE_URL } from '../global/envs';
import { useUserStore as userStore } from '../store/useUserStore';
import { storage } from '../utils/localStorage';

export const api = ky.extend({
  prefixUrl: API_BASE_URL,
  timeout: false,
  retry: 10,
  hooks: {
    beforeRequest: [
      (request) => {
        const token = userStore.getState().authToken;
        request.headers.set('Authorization', `Bearer ${token}`);

        const impersonateId = storage.getString(X_IMPERSONATE);
        if (impersonateId) {
          request.headers.set('Content-Language', impersonateId);
        }
      },
    ],
    afterResponse: [
      async (input, options, response) => {
        if (response.status === 401) {
          userStore.getState().setHasAuthError();
        }
      },
    ],
  },
});
